// @import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import "./global.scss";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Manrope";
  color: $text-main;
  // font-family: "Roboto", sans-serif;
}
.show-on-scroll {
  transition: 600ms cubic-bezier(0.07, 0.89, 0.79, 0.95);
  transform: translateY(100px);
  opacity: 0;

  &:nth-child(1) {
    transition-delay: 0ms;
  }
  &:nth-child(2) {
    transition-delay: 150ms;
  }
  &:nth-child(3) {
    transition-delay: 300ms;
  }
  &:nth-child(4) {
    transition-delay: 450ms;
  }
  &:nth-child(5) {
    transition-delay: 600ms;
  }
  &:nth-child(6) {
    transition-delay: 750ms;
  }
  &:nth-child(7) {
    transition-delay: 900ms;
  }
  &:nth-child(8) {
    transition-delay: 1050ms;
  }
  &:nth-child(9) {
    transition-delay: 1200ms;
  }
  &:nth-child(10) {
    transition-delay: 1350ms;
  }
  &:nth-child(11) {
    transition-delay: 1500ms;
  }
  &:nth-child(12) {
    transition-delay: 1650ms;
  }
  &:nth-child(13) {
    transition-delay: 1800ms;
  }
  &:nth-child(14) {
    transition-delay: 1950ms;
  }
  &:nth-child(15) {
    transition-delay: 2100ms;
  }
}

.is-visible {
  transform: translateY(0px) !important;
  opacity: 1 !important;
}

footer {
  // background-color: $color-main;
  background: url("../../public/assets/footer.svg");
  background-size: cover;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .contact-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-variant-numeric: lining-nums;
    width: 100%;
    // margin-top: 30px;
    position: absolute;
    top: 60%;
    transform: translateY(-50%);

    .pill {
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      padding: 10px 15px;
      margin: 20px;
      min-width: 205px;
      border-radius: 2rem;
      text-decoration: none;
      overflow: hidden;
      box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);

      ion-icon {
        width: 20px;
        height: 20px;
        padding: 5px;
        margin-right: 10px;
        background: #fa6400;
        color: white;
        border-radius: 50%;
      }
    }
  }

  .round {
    width: 100%;
    position: absolute;
    top: -10px;
    // transform: rotate(180deg);
  }

  .card {
    max-width: 90%;
    overflow: hidden;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0px 58px 5px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: -10px;
    .wrapper {
      position: relative;
      overflow: hidden;
      padding: 55px;
      width: 60;
      .top-abstract {
        position: absolute;
        top: -5px;
        right: -5px;
        width: 50%;
      }
      .header {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
      }
      .footer-title {
        font-size: 30px;
      }
      .footer-message {
        z-index: 1;
        margin-top: 10px;
        position: relative;
        width: 65%;
        color: #5b708b;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: -0.03em;
        text-align: left;
        margin-bottom: 23px;
      }
      .footer-logo {
        z-index: 1;
        width: 120px;
      }

      #newsletter {
        position: relative;
        display: flex;
        width: max-content;

        input {
          box-sizing: border-box;
          outline: none;
          border: none;
          border: rgba($color: $text-main, $alpha: 0.3) 1px solid;
          padding: 10px 20px;
          border-radius: 5px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          margin-right: 0px;

          & ~ label {
            position: absolute;
            top: 0;
            transform: translateY(-50%);
            // font-weight: bold;
            font-size: 14px;
            background: #fff;
            // color: $text-secondary;
            padding: 0px 5px;
            left: 10px;
          }
        }
        button {
          background: $color-main;
          border: none;
          outline: none;
          font-family: "Manrope";
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 20px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          border-left: none;
          transition: 300ms ease-in-out;
          ion-icon {
            color: #fff;
          }

          &:hover {
            background: lighten($color: $color-main, $amount: 10);
            cursor: pointer;
          }
        }
      }

      .privacy-policy-check {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-top: 30px;

        input {
          margin-left: 10px;
        }

        span {
          a {
            color: $color-main;
            text-decoration: none;

            &:hover {
              cursor: pointer;
            }

            &:visited,
            &:active {
              text-decoration: none;
              color: $color-main;
            }
          }
        }
      }
    }
  }
}

.align-left {
  align-items: flex-start !important;
}

.align-center {
  align-items: center !important;
}

.align-right {
  align-items: flex-end !important;
}

.p-20 {
  padding: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  &.max-1200 {
    max-width: 1200px;
  }

  &.vh-100 {
    height: 100vh;
  }
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 100%;
  flex: 1;

  &.bg-abstract {
    background: url("../../public/assets/abstract-bg.svg");
    background-size: cover;
    position: relative;

    .img-container {
      position: relative;
      width: 65%;
      height: 50%;
    }
    img {
      position: absolute;
      &[alt~="accept-icon"] {
        bottom: -10%;
        right: -10%;
      }
      &[alt~="add-user"] {
        top: -10%;
        left: -10%;
        z-index: 1;
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
          0 10px 10px -5px rgba(0, 0, 0, 0.04);
      }
      &[alt~="bookings-cartoon"] {
        top: -2%;
        // transform: scale(1.5);
        right: -30%;
      }
      &[alt~="booking"] {
        top: 90%;
        left: -8%;
        z-index: 1;
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
          0 10px 10px -5px rgba(0, 0, 0, 0.04);
      }
      &[alt~="calendar-icon"] {
        top: 16%;
        left: 12%;
      }
      &[alt~="gym-icon"] {
        top: 72%;
        right: 9%;
      }
      &[alt~="users-cartoon"] {
        top: 46%;
        left: 3%;
      }
      &[alt~="user-icon"] {
        top: 35%;
      }
    }
  }

  .red {
    background: rgba($color: red, $alpha: 0.3);
  }
  .blue {
    background: rgba($color: blue, $alpha: 0.3);
  }
}

section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  // &:not(::last-child) {
  margin-bottom: 50px;
  // }

  // Main section
  .main-info {
    min-height: 400px;
    display: flex;
    max-width: 65%;
    text-align: left;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img.left {
      width: 250px;
    }

    .title {
      font-size: 55px;
      &.left {
        width: 100%;
        text-align: left;
      }
      & span {
        display: inline-block;
      }
    }

    .subtitle {
      font-size: 22px;
      color: rgba($color: $text-main, $alpha: 0.6);

      &.max-300 {
        max-width: 400px;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-top: 20px;

      li {
        ion-icon {
          margin-right: 10px;
        }
        font-size: 25px;
        display: flex;
        align-items: center;
        padding: 10px;
        padding-left: 0;
      }
    }

    .btn-group {
      display: flex;
      margin-top: 30px;
    }
  }
  // Info section
  .about-info-tit {
    font-size: 32px;
    box-sizing: border-box;
    padding: 0px 30px;
    // max-width: 50%;
  }
  .about-info-sub {
    font-size: 18px;
    box-sizing: border-box;
    padding: 0px 30px;
    color: $text-secondary;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.03em;
    margin-bottom: 23px;
  }
  // Benefits
  &.benefits {
    margin-top: 65px;
    .wrapper {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      max-width: 1200px;
      .card {
        flex: 0 1 310px;
        img {
          width: 100%;
          border-radius: 10px;
        }

        .title {
          margin-top: 20px;
          margin-bottom: 10px;
          text-align: left;
          font-size: 20px;
          min-height: 55px;
          text-align: left;
        }

        .body {
          font-size: 16px;
          color: $text-secondary;
          line-height: 29px;
          letter-spacing: -0.02em;
          margin-bottom: 8px;
          text-align: left;
        }
      }
    }
  }
  // How it works
  &.how-it-works,
  &.demo {
    .disclaimer {
      margin: 75px;
      background: linear-gradient(
        45deg,
        rgba(250, 100, 0, 0.5),
        rgba(250, 100, 0, 0.2)
      );
      padding: 20px;
      border-radius: 0.25rem;
      color: #fff;

      a {
        color: #fff;
      }
    }

    .card-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      // margin-top: 35px;
      width: 100%;
      margin-bottom: 100px;
      .card {
        min-width: 300px;
        margin: 20px;
        border-radius: 0.5rem;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.16),
          0 4px 6px -2px rgba(0, 0, 0, 0.05);
        transition: 300ms ease-in-out;

        &:hover {
          transform: scale(1.1) !important;
          box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
        }

        .username,
        .password {
          position: relative;
          margin-bottom: 15px;
          .title {
            font-size: 14px;
            position: absolute;
            top: -9px;
            background: #fff;
            left: calc(8% + 10px);
            background: #fff;
            padding: 2px 8px;
            color: #fa6400;
          }
          .content {
            ion-icon {
              width: 20px;
              padding: 0px;
              margin: 0px;
              color: rgba(0, 0, 0, 0.65);
              margin-right: 10px;
            }
            width: 85%;
            margin: auto;
            border: 1px solid #fa6400;
            padding: 10px 10px;
            padding-left: 16px;
            border-radius: 0.5rem;
            font-family: "Roboto Mono";
            font-size: 16px;
            display: flex;
            align-items: center;
          }
        }
        .type {
          text-align: center;
          font-size: 22px;
          font-weight: bold;
          position: relative;
          padding-top: 15px;

          &::before {
            content: "";
            position: absolute;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
            width: 85%;
            height: 1px;
            background: rgba(0, 0, 0, 0.1);
          }
        }

        .type-icon {
          margin: 35px auto;
          width: 95px;
          height: 95px;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px;
          border-radius: 50%;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -2px rgba(0, 0, 0, 0.05);
        }

        .btn-demo {
          text-decoration: none;
          text-transform: uppercase;
          letter-spacing: 2px;
          width: 85%;
          padding: 15px 20px;
          text-align: center;
          margin: 50px auto;
          display: block;
          border-radius: 0.25rem;
        }

        &.gym {
          background: linear-gradient(
            45deg,
            lighten(#fa6400, 20%),
            lighten(#fae250, 20%)
          );
          background-size: 300%;

          .type-icon {
            color: lighten(#fa6400, 17%);
          }
          & *:not(.type-icon) {
            color: #fff;
          }

          .content {
            border-color: rgba(255, 255, 255, 0.5);
            ion-icon {
              color: rgba(255, 255, 255, 0.75);
            }
          }
          .type::before {
            background-color: rgba(255, 255, 255, 0.5);
          }

          .btn-demo {
            background: rgba(255, 255, 255, 0.25);
            color: #fff;
            transition: 300ms ease-in-out;

            &:hover {
              background: rgba(255, 255, 255, 1);
              color: lighten(#fa6400, 17%);
            }
          }
        }

        &.user {
          background: radial-gradient(lighten(#fff, 20%), lighten(#000, 96%));
          background-size: 300%;

          .type-icon {
            color: lighten(#000, 55%);
          }
          & *:not(.type-icon) {
            color: lighten(#000, 45%);
          }

          .content {
            border-color: lighten(#000, 55%);
            ion-icon {
              color: lighten(#000, 55%);
            }
          }
          .type::before {
            background-color: lighten(#000, 55%);
          }
        }

        .btn-demo {
          background: rgba(0, 0, 0, 0.15);
          color: #fff;
          transition: 300ms ease-in-out;

          &:hover {
            background: rgba(0, 0, 0, 1);
            color: #fff;
          }
        }
      }
    }

    margin-top: 100px;
    h1 {
      font-size: 45px;
      text-align: center;
      width: 100%;
    }
    h3 {
      font-family: "Roboto Mono", monospace;
      color: $text-secondary;
      text-align: center;
      width: 100%;
    }
    .col.content {
      padding: 50px;
    }
    .title {
      font-weight: 600;
      font-size: 38px;
      line-height: 110%;
      letter-spacing: -0.05em;
      margin-bottom: 10px;
    }

    .display-none {
      display: none !important;
    }

    .underline {
      position: relative;
      &:before {
        content: "";
        z-index: -1;
        width: 100%;
        height: 20px;
        border-radius: 2px;
        position: absolute;
        bottom: 5px;
        background: rgba($color: $color-main, $alpha: 0.4);
      }
    }

    .images {
      position: relative;
      width: 100%;
      height: 100%;
      img {
        position: absolute;
        &[alt~="register"] {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
        }
        &[alt~="triangles"] {
          top: 0;
          right: 0;
        }
        &[alt~="weight"] {
          top: -29px;
          left: 8%;
        }
        &[alt~="excel"] {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(0.5) !important;
          box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
            0 10px 10px -5px rgba(0, 0, 0, 0.04);
          border-radius: 1rem;
        }
        &[alt~="subscribe"] {
          right: 10%;
          top: 10%;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -2px rgba(0, 0, 0, 0.05);
          border-radius: 50%;
        }
        &[alt~="users"] {
          left: 10%;
          bottom: 10%;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -2px rgba(0, 0, 0, 0.05);
          border-radius: 50%;
        }
        &[alt~="calendar"] {
          bottom: 10%;
          left: 10%;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -2px rgba(0, 0, 0, 0.05);
          border-radius: 50%;
        }
        &[alt~="gym"] {
          z-index: 1;
          top: 10%;
          left: 10%;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -2px rgba(0, 0, 0, 0.05);
          border-radius: 50%;
        }
        &[alt~="squares"] {
          top: 0;
          left: 0;
        }
        &[alt~="timetable"] {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) !important;
        }
        &[alt~="mail"] {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -2px rgba(0, 0, 0, 0.05);
          border-radius: 25px;
          z-index: 1;
        }
      }
    }

    .subtitle {
      color: #5b708b;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.03em;
      margin-bottom: 23px;
    }

    .row:nth-child(2n + 1) {
      flex-direction: row-reverse;
    }
  }
}

@media only screen and (max-width: 1420px) {
  #main .row .col.bg-abstract {
    overflow: hidden;
    align-items: center;
  }
}

@media only screen and (max-width: 1184px) {
  footer {
    .card {
      .wrapper {
        .header {
          .footer-logo {
            margin-bottom: 20px;
          }
          flex-direction: column;
        }

        .footer-message {
          width: unset;
          text-align: center;
          margin-bottom: 40px;
        }
        #information-messages {
          p {
            max-width: unset !important;
            margin: auto;
          }
        }
        #newsletter {
          justify-content: center;
          margin: auto;
        }
      }
    }
  }

  .row {
    flex-direction: column;
  }
  .col {
    flex: unset;
  }

  .align-left {
    align-items: center !important;
    justify-content: center !important;
  }

  .align-right {
    align-items: center !important;
    justify-content: center !important;
  }

  .main-info {
    max-width: unset;
  }

  section {
    .about-info-tit {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  * {
    text-align: center;
  }

  .how-it-works,
  .demo {
    .row {
      .col:nth-child(2) {
        flex: unset;
        width: 100%;
        height: 300px;
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  #main {
    .row {
      justify-content: center;
    }
  }
  #demo,
  #how-it-works {
    .row {
      .col {
        .images {
          overflow: hidden;
        }
      }
    }
  }
  footer {
    .contact-info {
      top: 70%;
    }
  }
}

@media only screen and (max-width: 480px) {
  #demo,
  #how-it-works > div.row div.col.content {
    padding: 20px;
  }

  section .main-info ul li {
    font-size: 20px;
  }

  footer {
    height: 100vh;
    .contact-info {
      top: 80%;

      .pill {
        margin-top: 10px;
        margin-bottom: 0px;
      }
    }
  }

  footer .card .wrapper {
    padding: 20px;
  }
}
