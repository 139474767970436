@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap");

$color-main: #fa6400;
$color-secondary: #fae205;
$text-main: #2f3538;
$text-secondary: #5b708b;
$gradient-main: linear-gradient(
  to right top,
  #fa6400,
  #fc7500,
  #fd8500,
  #fe9400,
  #fea205
);

html {
  scroll-behavior: smooth;
}

.body {
  font-family: "Manrope";
  scroll-behavior: smooth;
  line-height: 30px;
  position: relative;
}

span {
  display: inline-block;
}

.hidden {
  opacity: 0;
}

.btn {
  background: $gradient-main;
  background-size: 200%;
  display: flex;
  width: 150px;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  padding: 15px 20px;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
  border-radius: 5px;
  color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 300ms ease-in-out;

  &:first-child {
    margin-right: 10px;
  }

  &:hover {
    background: lighten($color: $color-main, $amount: 30);
    color: lighten($color: white, $amount: 30);
  }
}

.outline {
  @extend .btn;
  background: white;
  color: $color-main;
}

.black {
  background-color: black;
}

#loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: #fff;
  // transition: 1.2s cubic-bezier(0.28, 0.11, 0.32, 1);

  img {
    width: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.fade-out {
  animation: fade-out 1.2s normal forwards cubic-bezier(0.28, 0.11, 0.32, 1);
}

// Fade out animation
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
